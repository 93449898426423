<template>
  <v-panel class="MediaNotificationsPanel">
    <template slot="heading">
      <div class="columns is-aligned-middle is-mobile">
        <div class="column is-flex is-aligned-middle">
          <div class="m-l-s">
            {{ notification.title }}
          </div>
        </div>

        <div
          v-if="mediaTypes.length > 0"
          class="column is-narrow border-r-grey-lighter"
        >
          <clipping-type-icon
            v-for="icon in mediaTypes"
            :key="icon"
            :type="icon"
          />
        </div>

        <div class="column is-narrow border-r-grey-lighter">
          <i18n path="components.media_notification_panel.streams_count">
            <span slot="count">{{ streams.length }}</span>
          </i18n>
        </div>

        <div class="column is-narrow">
          {{ notification.is_enabled ? $t('general.enabled') : $t('general.disabled') }}
        </div>
      </div>
    </template>

    <table class="table is-transparent has-cells-vertically-centered xhas-no-borders m-v-none m-h-a">
      <tbody>
        <tr>
          <td class="is-narrow">
            <div class="column is-narrow is-bold">
              Days
            </div>
          </td>
          <td>
            {{ sendOutDays }}
          </td>
        </tr>
        <tr v-if="notification.next_send_at">
          <td class="is-narrow">
            <div class="column is-narrow is-bold">
              {{ $t('pages.settings.personal.media_notifications.sending_at') }}
            </div>
          </td>
          <td>
            {{ datetimeTz(notification.next_send_at, notification.timezone) }} {{ getTimezoneString(notification.timezone) }}
          </td>
        </tr>
        <tr v-if="streams.length">
          <td class="is-narrow">
            <div class="column is-narrow is-bold">
              {{ $t('general.streams') }}
            </div>
          </td>
          <td>
            {{ streamNames }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="buttons is-right m-t-m p-t-m border-t-grey-light">
      <v-button
        :loading="isDeleting"
        class="is-black is-plain"
        data-testid="MediaNotificationsPanel__Delete"
        @click="deleteMediaNotification"
      >
        {{ $t('general.delete') }}
      </v-button>

      <v-button
        class="is-black"
        data-testid="MediaNotificationsPanel__Edit"
        @click="selectNotification"
      >
        {{ $t('forms.edit') }}
      </v-button>
    </div>
  </v-panel>
</template>

<script>
import isEqual from 'lodash/isEqual'
import timezones from '@/constants/timezones'
import * as ArrayUtils from '@hypefactors/shared/js/utils/arrayUtils'
import { MediaNotificationsApiService } from '@/services/api/MediaNotificationsApiService'
import DatetimeTzMixin from '@hypefactors/shared/js/mixins/DatetimeTzMixin'

/**
 * @module MediaNotificationsPanel
 */
export default {
  name: 'MediaNotificationsPanel',

  mixins: [DatetimeTzMixin],

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isDeleting: false
    }
  },

  computed: {
    sendOutDays () {
      const days = this.notification.days.slice()

      if (isEqual(days, ['sat', 'sun'])) {
        return this.$t('general.days_of_week.weekends')
      }

      if (isEqual(days, ['mon', 'tue', 'wed', 'thu', 'fri'])) {
        return this.$t('general.days_of_week.weekdays')
      }

      if (days.length === 7) {
        return this.$t('general.days_of_week.every_day')
      }

      return days.map(day => {
        return this.$t(`general.days_of_week.${day}`)
      }).join(', ')
    },

    streams () {
      return this.$safeGet(this.notification, 'streams')
    },

    mediaTypes () {
      return ArrayUtils.removeDuplicatesSimple(this.streams.reduce((all, current) => {
        return all.concat(current.source_types)
      }, []))
    },

    streamNames () {
      return this.streams.map(stream => stream.title).join(', ')
    }
  },

  methods: {
    selectNotification () {
      this.$emit('edit', this.notification)
    },

    getTimezoneString (timezone) {
      return timezones[timezone]
    },

    async deleteMediaNotification () {
      try {
        await this.$confirm('Deleting a notification configuration is permanent and irreversible. Are you sure to proceed?')

        await MediaNotificationsApiService.delete(this.notification.id)

        this.$emit('deleted', this.notification)
      } catch (e) {
        this.$displayRequestError(e)
      }
    }
  }
}
</script>
