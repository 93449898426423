import Api from '@/services/Api'

export const MEDIA_NOTIFICATIONS_ENDPOINT = '/user/media-notifications'
export const MEDIA_NOTIFICATION_ENDPOINT = (notificationId) => `/user/media-notifications/${notificationId}`

export const MediaNotificationsApiService = {
  fetchAll () {
    return Api.getData(MEDIA_NOTIFICATIONS_ENDPOINT)
  },

  create (payload) {
    return Api.postData(MEDIA_NOTIFICATIONS_ENDPOINT, payload)
  },

  update (id, payload) {
    return Api.putData(MEDIA_NOTIFICATION_ENDPOINT(id), payload)
  },

  find (id) {
    return Api.getData(MEDIA_NOTIFICATION_ENDPOINT(id))
  },

  delete (id) {
    return Api.deleteData(MEDIA_NOTIFICATION_ENDPOINT(id))
  }
}
